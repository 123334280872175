import { useTranslation } from '@infra/i18n';
import React from 'react';

import { PageContainer, Wrapper } from './styled-components';

const UserAgreementContent = () => {
  const { t } = useTranslation('userAgreementPage');

  return (
    <PageContainer>
      <Wrapper dangerouslySetInnerHTML={{ __html: t('text') }} />
    </PageContainer>
  );
};

export { UserAgreementContent };
