import { LayoutDefault } from '@features/layouts/layout-default';
import { SEO } from '@features/seo';
import { gatsbyPageProps } from '@infra/gatsby/types';
import { StaticSiteHero } from '@organisms/static-site-hero';
import { UserAgreementContent } from '@organisms/user-agreement-content';
import React from 'react';

const UserAgreementPage = props => {
  const {
    pageContext: { locale },
    location,
  } = props;

  return (
    <LayoutDefault locale={locale} location={location}>
      <SEO
        location={location}
        title="userAgreementPage.metaTitle"
        description="userAgreementPage.metaDescription"
        lang={locale}
      />
      <StaticSiteHero title="userAgreementPage.userAgreementOfService" />
      <UserAgreementContent />
    </LayoutDefault>
  );
};

UserAgreementPage.propTypes = {
  ...gatsbyPageProps,
};

export { UserAgreementPage };
export default UserAgreementPage;
